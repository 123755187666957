import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'carbon-components-react';
import { Maximize32 } from '@carbon/icons-react';
import { DSH_Figma_Utils } from 'src/common/figmaListener';
import countryList from '../data/currencies.json';
import { LangDefaults, useLang } from 'src/common/hooks/widgets/useLang';
import { useEnv } from 'src/common/hooks/widgets/useEnv';
import PublicImage from 'src/common/components/PublicImage';

import './FigmaDemo.scss';
import { FigmaConfig, segmentTracking } from '../types/types';
import DemoCtas from './DemoCtas';

//needed for languages - another change
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { swapCountryAndLanguage } from 'src/common/utils/swapCountryAndLanguage';

export interface FigmaDemoProps {
  langCode: string;
  test: boolean;
  countryToTest: string;
  demoConfig: string;
}
const FigmaDemo = ({
  langCode,
  test,
  countryToTest,
  demoConfig,
}: FigmaDemoProps) => {
  const [environment] = useEnv();
  const [usingFigmaListener, setUsingFigmaListener] = useState<boolean>(false);
  const [config, setConfig] = useState<FigmaConfig | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [styleOverride, setStyleOverride] = useState<object | null>(null);
  const [fullscreenLabel, setFullscreenLabel] =
    useState<string>('Launch fullscreen');

  //lets set the language for use...
  const [currency, setCurrency] = useState('');
  const [country, setCountry] = useState('');
  const localeCode = swapCountryAndLanguage(langCode as IBMLocale);
  const [urxlang, setUrxLang] = useState(
    LangDefaults.localCountryLang as IBMLocale,
  );

  const [lang, userLocale] = useLang(
    countryList,
    test,
    countryToTest,
    setCurrency,
    setCountry,
    localeCode,
  );

  console.log(currency, country, urxlang);

  // Create refs for the iframe and button
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const fullscreenBtnRef = useRef<HTMLButtonElement | null>(null);

  const loadFigmaTracking = function () {
    const finishedLoading = function () {
      const demoCover = document.querySelector(
        '.figma_demo_wrapper .figma_loading_cover',
      );
      setTimeout(function () {
        demoCover?.classList.add('fade_out');
      }, 250);
    };
    const clickCallbackFunc = (nodeID: string) => {
      if (config?.click_triggers) {
        console.log('Figma ID of clicked element is: ', nodeID);
        const clickTriggers = config?.click_triggers ?? [];
        const cTrigger = clickTriggers.find((trigger) =>
          trigger.ids.includes(nodeID),
        );

        if (cTrigger) {
          switch (cTrigger.type) {
            case 'click':
              const element = document.querySelector(
                cTrigger.action,
              ) as HTMLElement;
              element?.click();
              break;
            case 'url':
              window.open(cTrigger.action);
              break;
            case 'shadowroot':
              const selectors = cTrigger.action.split(':'); // Split the action on the colon
              const srElement = document.querySelector(selectors[0]); // Get the first part of the selector (the base element)
              if (selectors.length > 1 && srElement) {
                // If there's a second part, chain the querySelector calls
                const targElm = srElement?.shadowRoot?.querySelector(
                  selectors[1],
                ) as HTMLElement;
                targElm?.click(); // Typecast the element to HTMLElement to safely access .click()
              }
              break;
          }
        }
      }
    };

    DSH_Figma_Utils.init({
      figma_fileKey: config?.figma_file_key || '',
      figma_accessToken: config?.figma_access_token || '',
      product_Title: config?.product_title || '',
      clickCallback: clickCallbackFunc,
      metricsTracking: segmentTracking,
      loadedCallback: finishedLoading,
      page_Title: config?.demo_title || '',
    });
  };

  const fetchDemoMatrix = async () => {
    fetchFigmaConfig(userLocale); // Pass the data directly to fetchFigmaConfig, will rewrite for next release
  };

  const fetchFigmaConfig = async (userLocalePassed: string) => {
    let url = null;
    // let langConfig = null;
    const passedUserLocale = userLocalePassed;
    console.log(passedUserLocale);

    if (demoConfig) {
      url =
        'https://hybrid-cloud-widgets-development.s3.us.cloud-object-storage.appdomain.cloud/widgets/turbonomic-demo-widget/' +
        demoConfig +
        '.json';
    } else {
      return;
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          'Config file could not be loaded.  Please make sure ' +
            demoConfig +
            ' esists.',
        );
      }
      const data: FigmaConfig = await response.json();
      setConfig(data);

      if (data.style_override) {
        setStyleOverride(data.style_override);
      }

      if (data.fullscreen_label) {
        setFullscreenLabel(data.fullscreen_label);
      }
      //right here we need to check if the current user locale exists in the config file, otherwise we want to use the default en.
      if (data.figma_access_token && data.figma_file_key) {
        setUsingFigmaListener(true);
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  /* Have urx form deault in us-en when on language us page */
  useEffect(() => {
    const fetchedUrxLang = localeCode; // us-en
    setUrxLang(userLocale as IBMLocale); // de-de
    if (fetchedUrxLang !== lang) {
      setUrxLang(fetchedUrxLang as IBMLocale); // default us-en
    }
  }, [userLocale]);

  useEffect(() => {
    const iframe = iframeRef.current; //
    const fullscreenBtn = fullscreenBtnRef.current;
    if (iframe && fullscreenBtn) {
      const launchFullscreen = (element: HTMLElement) => {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        }
      };

      const handleFullscreenClick = (
        e: React.MouseEvent<HTMLButtonElement>,
      ) => {
        e.preventDefault();
        launchFullscreen(iframe);
      };

      const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && document.fullscreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
      };

      fullscreenBtn.addEventListener(
        'click',
        handleFullscreenClick as unknown as EventListener,
      );
      document.addEventListener('keydown', handleEscapeKey);

      // Clean up event listeners on unmount
      return () => {
        fullscreenBtn.removeEventListener(
          'click',
          handleFullscreenClick as unknown as EventListener,
        );
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }

    fetchDemoMatrix();
  }, [loading, userLocale]);

  if (loading)
    return (
      <div className="widget_loading_wrapper">
        <div className="widget_msg">
          <p>No config file passed in...</p>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="widget_loading_wrapper">
        <div className="widget_msg">
          <p>Error: {error}</p>
        </div>
      </div>
    );
  if (!config)
    return (
      <div className="widget_loading_wrapper">
        <div className="widget_msg">
          <p>No configuration data found</p>
        </div>
      </div>
    );

  return (
    <div className="figma_demo_widget_wrap">
      {usingFigmaListener && loadFigmaTracking()}
      <div className="demo_button_bar">
        <div className="left">
          {config?.ctas && (
            <DemoCtas
              urxlang={userLocale}
              ctas={config.ctas}
              env={environment}
              demotitle={config.demo_title}
              producttitle={config.product_title}
            />
          )}
        </div>
        <div className="right">
          <Button
            id="fullscreen-btn"
            className="fullscreen_btn"
            ref={fullscreenBtnRef}
            renderIcon={Maximize32}
            kind="tertiary"
          >
            {fullscreenLabel}
          </Button>
        </div>
      </div>
      <div
        className="figma_demo_wrapper"
        style={styleOverride ? { ...styleOverride } : {}}
      >
        {config?.figma_url ? (
          <iframe
            ref={iframeRef}
            tabIndex={-1}
            className="figma_frame"
            onLoad={() => {
              window.parent.focus();
            }}
            title="Figma demo content"
            id="figma_demo"
            src={config.figma_url}
            allowFullScreen
          ></iframe>
        ) : (
          <div className="widget_msg">
            <p>No figma url was provided in the config.</p>
          </div>
        )}
        {usingFigmaListener === true ? (
          <div className="figma_loading_cover">
            <PublicImage
              imgPath={'images/rebus.png'}
              widgetId="figma-demo-widget"
              alt="animation-image"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FigmaDemo;
