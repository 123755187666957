export type Utils_type = {
  getFigmaNodeData: (nodeId: string) => Promise<any>;
  addWindowListener: (listenerObjDef: ListenerObjDef) => void | undefined;
};
export interface segmentProperties {
  productTitle?: string;
  pageTitle: string;
  object: string;
  pageUrl?: string;
  successFlag?: boolean;
  resultValue: string;
  CTA: string;
  location: string;
  action: string;
  field: string;
}

export interface figmaParameters {
  figma_fileKey: string;
  figma_accessToken: string;
  metricsTracking?: (
    eventName: string,
    {
      pageTitle,
      object,
      resultValue,
      CTA,
      location,
      action,
      field,
    }: segmentProperties,
  ) => void;
  clickCallback?: (nodeId: string) => void;
  slideCallback?: (nodeId: string) => object | undefined;
  loadedCallback?: () => void;
  product_Title: string;
  page_Title: string;
}

/* interface to move up in the file */
export interface ListenerObjDef {
  productTitle: string;
  pageTitle: string;
  metricsTracking: any;
  clickCallback?: (nodeId: string) => void;
  slideCallback?: (nodeId: string) => void;
  loadedCallback?: () => void;
}

export type DSH_Figma_Utils_type = {
  figma_accessToken: string;
  figma_fileKey: string;
  slideStart: number;
  current: string | undefined;
  Utilities: Utils_type;
  init: (paramsObj: figmaParameters) => void;
};

const DSH_Figma_Utils = {} as DSH_Figma_Utils_type;
// Add functionality to the namespace
DSH_Figma_Utils.Utilities = {
  // Function to get node information from Figma
  getFigmaNodeData: async function (nodeId) {
    if (!nodeId || nodeId === '') {
      return null;
    }

    const url = `https://api.figma.com/v1/files/${DSH_Figma_Utils.figma_fileKey}/nodes?ids=${nodeId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-Figma-Token': DSH_Figma_Utils.figma_accessToken,
        },
      });
      const data = await response.json();
      const nodeData = data.nodes[nodeId];

      return nodeData;
    } catch (error) {
      console.error('Error retrieving node information:', error);
    }
  },
  // Called by init to set event listeners
  addWindowListener: function (listenerObj) {
    window.addEventListener(
      'message',
      async (event) => {
        if (event.origin !== 'https://www.figma.com') {
          return;
        }
        //what we want to do on the initial load
        if (event.data.type === 'INITIAL_LOAD') {
          DSH_Figma_Utils.slideStart = Date.now(); //set the default starting time to be used for duration tracking
          listenerObj.metricsTracking('UI Interaction', {
            productTitle: listenerObj.productTitle,
            pageTitle: listenerObj.pageTitle,
            object: 'Figma',
            pageUrl: window.location.pathname,
            successFlag: true,
            CTA: '',
            location: 'Demo',
            action: 'Demo loaded',
            field: '',
          });
          if (typeof listenerObj.loadedCallback === 'function') {
            listenerObj.loadedCallback();
          }
        }
        //what we want to do when we detect a click
        if (event.data.type === 'MOUSE_PRESS_OR_RELEASE') {
          //if we have a passed in callback function for clicking, lets trigger that
          if (typeof listenerObj.clickCallback === 'function') {
            listenerObj.clickCallback(event.data.data.targetNodeId);
          }
          const click_ret = await DSH_Figma_Utils.Utilities.getFigmaNodeData(
            event.data.data.targetNodeId,
          );
          //this will call our metrics tracking for the clicked element starting with "cta-"
          if (
            click_ret?.document?.name?.toLowerCase().startsWith('cta-') ||
            click_ret?.document?.name?.toLowerCase().startsWith('cta- ')
          ) {
            listenerObj.metricsTracking('UI Interaction', {
              productTitle: listenerObj.productTitle,
              pageTitle: listenerObj.pageTitle,
              object: 'Figma',
              pageUrl: window.location.pathname,
              successFlag: true,
              CTA: click_ret.document.name,
              location: 'Demo',
              action: 'CTA clicked',
              field: '',
            });
          }
        }
        //what we want to do on a slide change
        if (event.data.type === 'PRESENTED_NODE_CHANGED') {
          if (typeof listenerObj.slideCallback === 'function') {
            listenerObj.slideCallback(event.data.data.presentedNodeId);
          }
          const slide_ret = await DSH_Figma_Utils.Utilities.getFigmaNodeData(
            event.data.data.presentedNodeId,
          );
          if (slide_ret?.document) {
            let slideType = 'Slide';
            if (
              slide_ret?.document?.name
                ?.toLowerCase()
                .startsWith('milestone-') ||
              slide_ret?.document?.name?.toLowerCase().startsWith('milestone -')
            ) {
              slideType = 'Milestone';
            }
            if (DSH_Figma_Utils.current === undefined) {
              //there is not current slide, so we take the loaded one and submit it
              DSH_Figma_Utils.current = slide_ret.document.name;
              listenerObj.metricsTracking('UI Interaction', {
                productTitle: listenerObj.productTitle,
                pageTitle: listenerObj.pageTitle,
                object: 'Figma',
                pageUrl: window.location.pathname,
                successFlag: true,
                CTA: DSH_Figma_Utils.current,
                location: 'Demo',
                action: slideType,
                field: '',
              });
            } else {
              //we need to take the current stored slide, and submit it with
              const now = Date.now();

              listenerObj.metricsTracking('UI Interaction', {
                productTitle: listenerObj.productTitle,
                pageTitle: listenerObj.pageTitle,
                object: 'Figma',
                pageUrl: window.location.pathname,
                successFlag: true,
                CTA: DSH_Figma_Utils.current,
                location: 'Demo',
                action: 'Duration',
                field: Math.floor((now - DSH_Figma_Utils.slideStart) / 1000),
              });

              listenerObj.metricsTracking('UI Interaction', {
                productTitle: listenerObj.productTitle,
                pageTitle: listenerObj.pageTitle,
                object: 'Figma',
                pageUrl: window.location.pathname,
                successFlag: true,
                CTA: slide_ret.document.name,
                location: 'Demo',
                action: slideType,
                field: '',
              });

              //update for the next slide to process
              DSH_Figma_Utils.current = slide_ret.document.name;
              DSH_Figma_Utils.slideStart = now;
            }
          }
        }
      },
      { passive: true },
    );
  },
};

/**
 *
 * @param paramsObj
 * @returns
 */

DSH_Figma_Utils.init = function (paramsObj: figmaParameters) {
  console.log(`Setting Figma tracking: `, paramsObj);
  if (!paramsObj.figma_fileKey && !paramsObj.figma_accessToken) {
    console.log(`Return -NO TRACKING-`);
    return;
  }
  let metricsTracking;
  if (
    !paramsObj.metricsTracking ||
    typeof paramsObj.metricsTracking != 'function' ||
    paramsObj.metricsTracking === null
  ) {
    metricsTracking = function (arg1: string, arg2: object) {
      console.log(arg1);
      console.log(arg2);
    };
  } else {
    metricsTracking = paramsObj.metricsTracking;
  }
  // Constants
  DSH_Figma_Utils.figma_fileKey = paramsObj.figma_fileKey;
  DSH_Figma_Utils.figma_accessToken = paramsObj.figma_accessToken; //this needs to be in a env variable
  // Variables
  DSH_Figma_Utils.slideStart = 0;
  DSH_Figma_Utils.current = undefined;
  // Create the object to pass into the listener event
  const listenerObjDef: ListenerObjDef = {
    productTitle: paramsObj.product_Title || '',
    pageTitle: paramsObj.page_Title || '',
    metricsTracking: metricsTracking,
  };
  if (
    paramsObj.clickCallback &&
    typeof paramsObj.clickCallback === 'function'
  ) {
    listenerObjDef.clickCallback = paramsObj.clickCallback;
  }
  if (
    paramsObj.slideCallback &&
    typeof paramsObj.slideCallback === 'function'
  ) {
    listenerObjDef.slideCallback = paramsObj.slideCallback;
  }
  if (
    paramsObj.loadedCallback &&
    typeof paramsObj.loadedCallback === 'function'
  ) {
    listenerObjDef.loadedCallback = paramsObj.loadedCallback;
  }
  DSH_Figma_Utils.Utilities.addWindowListener(listenerObjDef);
};

/* end of the figma lib functions */
export { DSH_Figma_Utils };
