import React, { useState } from 'react';
import { ArrowRight16 } from '@carbon/icons-react';
import { CtasButton } from '../types/types';
import { Button, ButtonSet } from 'carbon-components-react';
import { initUrxForm } from 'src/common/hooks/widgets/urxForm';
import { useCookiesURL } from 'src/common/hooks/widgets/useCookiesURL';
import Cookies from 'js-cookie';
import { segmentTracking } from '../types/types';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { Helmet } from 'react-helmet';
import { FormModal } from 'src/common/utils/FormModal';
import Thanks from './Thanks';

interface DemoCtasProps {
  ctas: CtasButton[]; // Array of CtasButton objects
  urxlang: string;
  env: string;
  demotitle: string;
  producttitle: string;
}

const DemoCtas: React.FC<DemoCtasProps> = (props) => {
  const companyId = '';
  const [drawerOpen, setDrawerOpen] = useState(false);
  const lang = props.urxlang;
  const [closeModal, setCloseModal] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [formId, setFormId] = useState('');
  const cookieURL = useCookiesURL();
  const pageUrl = window.location.pathname;

  //this is for the thank you modals
  const [openThankYou, setOpenThankYou] = useState(false);
  const [enableThankYou, setEnableThankYou] = useState(false);

  //we need to set the values for the form modal here
  const [thanksModalHeading, setThanksModalHeading] = useState('');
  const [thanksModalBody, setThanksModalBody] = useState('');
  const [thanksModalUrl, setThanksModalUrl] = useState('');
  const [thanksModalUrlLabel, setThanksModalUrlLabel] = useState('');
  const [thanksCloseModal, setThanksCloseModal] = useState('');

  window.urxEnvironment = props.env;
  window.onUrxFormSubmitSuccess = () => {
    setFormComplete(!formComplete);
    setDrawerOpen(false); // Hide Drawer on Thank you page
    setCloseModal(true);
    if (enableThankYou) {
      setOpenThankYou(true);
      setEnableThankYou(false);
    }
  };

  const setCookie = (formId?: string) => {
    let useThisForm = null;
    if (formId) {
      useThisForm = formId;
    }
    Cookies.set(
      'urxdcq',
      JSON.stringify({
        source: useThisForm,
        // One field to provide all values (stringify)
        // adding this for no reason, to trigger an update
        dcq: {
          Q_NOTESDATA: cookieURL + ' Demo Title: ' + props.demotitle,
        },
      }),
    );
  };

  return (
    <>
      <Helmet>
        <script
          type="application/javascript"
          src={`https://www.ibm.com/account/ibmidutil/widget/js/loader.js`}
        ></script>
        <script
          type="application/javascript"
          src={`https://www.ibm.com/account/ibmidutil/widget/js/main.js`}
        ></script>
      </Helmet>
      <div>
        <Thanks
          openThanks={openThankYou}
          heading={thanksModalHeading}
          body_text={thanksModalBody}
          url={thanksModalUrl}
          url_label={thanksModalUrlLabel}
          close_modal={thanksCloseModal}
          setShowThanksModal={setOpenThankYou}
        ></Thanks>
        <ButtonSet>
          {props.ctas.map((cta, index) =>
            cta.url ? (
              // Render the button for cta.url
              <Button
                id={`figma_demo_${index}`}
                key={index}
                href={cta.url}
                onClick={() => {
                  // window.open(cta.url, '_blank');
                  segmentTracking('CTA Clicked', {
                    pageTitle: props.demotitle,
                    object: 'Nav Header',
                    resultValue: 'CTA clicked',
                    CTA: cta.title,
                    location: 'Demo',
                    action: 'Clicked',
                    field: '',
                  });
                }}
              >
                {cta.title}
              </Button>
            ) : cta.urx ? (
              // Render the button for cta.urx
              <Button
                key={index}
                id={`figma_demo_${index}`}
                renderIcon={ArrowRight16}
                kind={
                  cta.type?.toLowerCase() !== 'secondary'
                    ? 'secondary'
                    : cta.type?.toLowerCase() === 'tertiary'
                    ? 'tertiary'
                    : 'primary' // Default to 'primary'
                }
                onClick={() => {
                  setFormId(cta.urx || '');
                  initUrxForm(
                    'preview',
                    'urx-form',
                    cta.urx || '',
                    props.urxlang as IBMLocale,
                    companyId,
                  );
                  if (cta.thanks) {
                    setThanksModalHeading(cta.thanks.heading);
                    setThanksModalBody(cta.thanks.body);
                    setThanksCloseModal(cta.thanks.close_modal);
                    if (cta.thanks.url) {
                      setThanksModalUrl(cta.thanks.url);
                    }
                    if (cta.thanks.url_label) {
                      setThanksModalUrlLabel(cta.thanks.url_label);
                    }
                    setEnableThankYou(true);
                  }
                  setDrawerOpen(true);
                  setCookie(cta.urx);
                  segmentTracking('CTA Clicked', {
                    pageTitle: props.demotitle,
                    object: 'Nav Header',
                    resultValue: 'CTA clicked',
                    CTA: cta.title,
                    location: 'Demo',
                    action: 'Clicked',
                    field: '',
                  });
                }}
              >
                {cta.title}
              </Button>
            ) : // Render nothing if neither cta.url nor cta.urx is present
            null,
          )}
        </ButtonSet>
      </div>
      <FormModal
        pageTitle={props.demotitle}
        object="Figma Demo"
        productTitle={props.producttitle}
        pageUrl={pageUrl}
        location={'Figma Demo Widget'}
        bpName={''}
        logo={''}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        formId={formId}
        lang={lang}
        id="urx-form"
        closeModal={closeModal}
        setCloseModal={setCloseModal}
      ></FormModal>
    </>
  );
};

export default DemoCtas;
