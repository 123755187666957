module.exports = {
  entry: './src/apps/figma-demo-widget/index.tsx',
  shortcode: 'figma-demo-widget',
  title: 'Figma Demo Widget',
  status: 'stable',
  description: 'Figma demo widget',
  preview: {
    url: `${process.env.PUBLIC_URL}/storybook/iframe.html?id=widgets-figma-demo-components--default&viewMode=story`,
  },
  settingsSchema: {
    type: 'object',
    properties: {
      fields: {
        type: 'object',
        required: ['demo_config'],
        properties: {
          demo_config: {
            type: 'string',
            title: 'Demo Config Name',
            description:
              'Enter the name of the demo config file without the .json extension as let us do the rest.',
            items: {
              type: 'string',
              pattern: '^[a-zA-Z0-9_.]+$',
            },
            examples: [['cognos_en_demo']],
          },
        },
      },
    },
  },
  useExternalPeerDependencies: ['react', 'react-dom', 'react-intl'],
  additionalCustomProperties: {
    webSegmentPaths: ['/cloud'],
    availableTranslations: [
      'ar',
      'de',
      'en',
      'es',
      'esla',
      'fr',
      'it',
      'ja',
      'ko',
      'pt',
      'zh-cn',
    ],
  },
};
