import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import normalizeWidgetInput from 'src/common/normalizeWidgetInput';
import { RenderFn } from 'src/types/widgets';

import FigmaDemo from './components/FigmaDemo';
import widgetDefinition from './FigmaDemo.widget';
import { FigmaDemoProps } from './types/types';

import 'carbon-components/css/carbon-components.min.css';

function refineInputTypes(input: Record<string, unknown>): FigmaDemoProps {
  //this is taking the config param and returning it for use within the widget.
  return { demoConfig: String(input.demoConfig) };
}

const render: RenderFn = async function (instanceId, langCode, origin, cb) {
  /* figma demo */
  const { element, locale, messages, params } =
    await normalizeWidgetInput<FigmaDemoProps>(
      instanceId,
      langCode,
      widgetDefinition,
      refineInputTypes,
    );

  if (!element || !locale) {
    return;
  }
  const localeCode = element.getAttribute('data-localecode') || 'en-us';

  ReactDOM.render(
    <React.StrictMode>
      <IntlProvider locale={locale} messages={messages} defaultLocale="en">
        <FigmaDemo
          langCode={localeCode}
          test={false}
          countryToTest={'us'}
          demoConfig={params.demoConfig}
        />
      </IntlProvider>
    </React.StrictMode>,
    element,
    () => cb(element),
  );
};

export default render;
